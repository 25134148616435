













import { Component, Prop, Vue } from "vue-property-decorator";
import { ReportDataSource, ReportFilter } from "../models";
import { getReportDataSources } from "../utils/ReportDataSourceUtil";

@Component
export default class ReportSources extends Vue {
  @Prop() report!: ReportFilter;

  get sources(): Array<ReportDataSource> {
    return getReportDataSources(this.report);
  }
}
