































































import { Component, Prop, Ref, Vue, Watch } from "vue-property-decorator";
import { cloneDeep } from "lodash";

import Operation from "./Operation.vue";
import Formatting from "./Formatting.vue";
import AggregationStrategy from "./AggregationStrategy.vue";
import CohortDays from "./CohortDays.vue";
import ConfirmedItem from "./ConfirmedItem.vue";
import ValidUtil from "@/shared/utils/ValidUtil";
import {
  BasicMetricModel,
  MetricStepId,
  OPERATION_BY_OPERATION_TYPE,
  UserMetricModel,
  UserMetricResultModel,
} from "@/reports/models";
import { VueForm } from "@/shared/types/ExtendedVueType";

@Component({
  components: {
    Operation,
    Formatting,
    AggregationStrategy,
    CohortDays,
    ConfirmedItem,
  },
})
export default class ResultSettings extends Vue {
  @Prop() value!: UserMetricModel;
  @Prop({ default: false }) isDone!: boolean;
  @Prop() lastAddedCohortDays!: Array<number>;

  @Ref("form") form!: VueForm;

  readonly requiredRule = [
    ValidUtil.required(this.$lang("validation.required")),
  ];
  valid = false;

  localResultSettingsValue = new UserMetricResultModel();
  localDaysValue: Array<number> = [];

  get confirmedBasicMetrics(): Array<BasicMetricModel> {
    return this.value.definition.basicMetrics;
  }

  get isLocalDone(): boolean {
    return this.isDone;
  }

  set isLocalDone(value: boolean) {
    this.$emit("changeIsDone", {
      value,
      stepValue: MetricStepId.RESULT_SETTINGS,
    });
  }

  get isNeedCohortDays(): boolean {
    return this.confirmedBasicMetrics.some(
      ({ cohort }: BasicMetricModel) => cohort
    );
  }

  get confirmedItemText(): Array<{
    key: string;
    value: string;
  }> {
    const {
      basicMetrics,
      resultSettings: { operands, operation, formatter, aggregatorStrategy },
    } = this.value.definition;

    return [
      ...(basicMetrics.length > 1 && operands.length && operation
        ? [
            {
              key: this.$lang("views.metrics.operands"),
              value: operands
                .map((value) =>
                  this.$lang(
                    `views.metrics.basicMetrics.${value.toLowerCase()}`
                  )
                )
                .join(", "),
            },
            {
              key: this.$lang("views.metrics.operation"),
              value: OPERATION_BY_OPERATION_TYPE.get(operation) as string,
            },
          ]
        : []),

      {
        key: this.$lang("views.metrics.formatter"),
        value: this.$lang(`views.metrics.${formatter.toLowerCase()}`),
      },
      {
        key: this.$lang("views.metrics.aggregatorStrategy"),
        value: this.$lang(`views.metrics.${aggregatorStrategy.toLowerCase()}`),
      },
      ...(this.isNeedCohortDays
        ? [
            {
              key: this.$lang("views.metrics.days"),
              value: this.value.days.join(", "),
            },
          ]
        : []),
    ];
  }

  @Watch("value.definition.resultSettings", { immediate: true, deep: true })
  private watchDefinitionResultSettings(value: UserMetricResultModel) {
    this.localResultSettingsValue = cloneDeep(value);
  }

  @Watch("value.days", { immediate: true, deep: true })
  private watchDays(value: Array<number>) {
    if (this.isNeedCohortDays) {
      if (!value.length) {
        this.localDaysValue = this.lastAddedCohortDays.length
          ? this.lastAddedCohortDays
          : [3, 7, 14, 30];
      } else {
        this.localDaysValue = cloneDeep(value);
      }
    } else {
      this.localDaysValue = [];
    }
  }

  handleEditingStep() {
    this.$emit("editStep", MetricStepId.RESULT_SETTINGS);
  }

  handleDeleteStep() {
    this.$emit("deleteStep", MetricStepId.RESULT_SETTINGS);
  }

  handleConfirmStep() {
    if (!this.form.validate()) {
      return;
    }

    const clonedValue = cloneDeep(this.value);
    clonedValue.definition.resultSettings = UserMetricResultModel.of(
      this.localResultSettingsValue
    );
    clonedValue.days = cloneDeep(this.localDaysValue);

    this.$emit("confirmStep", {
      stepValue: MetricStepId.RESULT_SETTINGS,
      value: clonedValue,
    });
  }
}
