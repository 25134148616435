import { ChartName } from "@/chart/models/ChartModel";
import { FilterId } from "@/shared/models";
import {
  MonetizationMonitoringFilter,
  ReportDataType,
  ReportFilter,
  ReportVisualizationChartModel,
  ReportVisualizationItemModel,
  ReportVisualizationModel,
} from "../models";

export const getReportVisualization = (
  report: ReportFilter
): Array<ReportVisualizationModel> => {
  return {
    AD_ROAS_COUNTRY: () => [
      new ReportVisualizationModel(
        [
          new ReportVisualizationItemModel(),
          new ReportVisualizationChartModel(
            ChartName.AD_ROAS_COUNTRY_TOTAL_CHART
          ),
        ],
        ReportDataType.TOTAL,
        true
      ),
      ...(report.groupByFilter.groupBy.length
        ? [
            new ReportVisualizationModel(
              [
                new ReportVisualizationItemModel(),
                new ReportVisualizationChartModel(
                  ChartName.AD_ROAS_COUNTRY_SUBTOTAL_CHART
                ),
              ],
              ReportDataType.SUB_TOTAL,
              true
            ),
          ]
        : []),
      new ReportVisualizationModel(
        [
          new ReportVisualizationItemModel(),
          ...report.charts.map(
            (name) => new ReportVisualizationChartModel(name)
          ),
        ],
        ReportDataType.DATA,
        true
      ),
    ],
    AD_ROAS_NETWORK: () => [
      new ReportVisualizationModel(
        [
          new ReportVisualizationItemModel(),
          new ReportVisualizationChartModel(
            ChartName.AD_ROAS_NETWORK_TOTAL_CHART
          ),
        ],
        ReportDataType.TOTAL,
        true
      ),
      ...(report.groupByFilter.groupBy.length
        ? [
            new ReportVisualizationModel(
              [
                new ReportVisualizationItemModel(),
                ...(report.groupByFilter.groupBy.length < 2
                  ? [
                      new ReportVisualizationChartModel(
                        ChartName.AD_ROAS_NETWORK_SUBTOTAL_CHART
                      ),
                    ]
                  : []),
              ],
              ReportDataType.SUB_TOTAL,
              true
            ),
          ]
        : []),
      new ReportVisualizationModel(
        [
          new ReportVisualizationItemModel(),
          ...(report.groupByFilter.groupBy.length < 2
            ? report.charts.map(
                (name) => new ReportVisualizationChartModel(name)
              )
            : []),
        ],
        ReportDataType.DATA,
        true
      ),
    ],
    ARPDAU: () => [
      new ReportVisualizationModel(
        [
          new ReportVisualizationItemModel(),
          ...(report.groupByFilter.groupBy.length < 2
            ? report.charts.map(
                (name) => new ReportVisualizationChartModel(name)
              )
            : []),
        ],
        ReportDataType.DATA,
        true,
        true
      ),
    ],
    ARPDAU_SIMPLE: () => [
      new ReportVisualizationModel(
        [
          new ReportVisualizationItemModel(),
          ...report.charts.map(
            (name) => new ReportVisualizationChartModel(name)
          ),
        ],
        ReportDataType.DATA,
        true,
        true
      ),
    ],
    ARPU: () => [
      new ReportVisualizationModel(
        [
          new ReportVisualizationItemModel(),
          new ReportVisualizationChartModel(ChartName.ARPU_REPORT_TOTAL_CHART),
        ],
        ReportDataType.TOTAL,
        true
      ),
      ...(report.groupByFilter.groupBy.length
        ? [
            new ReportVisualizationModel(
              [
                new ReportVisualizationItemModel(),
                ...(report.groupByFilter.groupBy.length < 2
                  ? [
                      new ReportVisualizationChartModel(
                        ChartName.ARPU_REPORT_SUBTOTAL_CHART
                      ),
                    ]
                  : []),
              ],
              ReportDataType.SUB_TOTAL,
              true
            ),
          ]
        : []),
      new ReportVisualizationModel(
        [
          new ReportVisualizationItemModel(),
          ...(report.groupByFilter.groupBy.length < 2
            ? report.charts.map(
                (name) => new ReportVisualizationChartModel(name)
              )
            : []),
        ],
        ReportDataType.DATA,
        true
      ),
    ],
    CALENDAR_CTR: () => [
      new ReportVisualizationModel(
        [new ReportVisualizationItemModel()],
        ReportDataType.TOTAL
      ),
      ...(report.groupByFilter.groupBy.length
        ? [
            new ReportVisualizationModel(
              [new ReportVisualizationItemModel()],
              ReportDataType.SUB_TOTAL
            ),
          ]
        : []),
      new ReportVisualizationModel(
        [
          new ReportVisualizationItemModel(),
          ...report.charts.map(
            (name) => new ReportVisualizationChartModel(name)
          ),
        ],
        ReportDataType.DATA,
        true
      ),
    ],
    CALENDAR_PER_MIN: () => [
      new ReportVisualizationModel(
        [new ReportVisualizationItemModel()],
        ReportDataType.TOTAL
      ),
      ...(report.groupByFilter.groupBy.length
        ? [
            new ReportVisualizationModel(
              [new ReportVisualizationItemModel()],
              ReportDataType.SUB_TOTAL
            ),
          ]
        : []),
      new ReportVisualizationModel(
        [
          new ReportVisualizationItemModel(),
          ...report.charts.map(
            (name) => new ReportVisualizationChartModel(name)
          ),
        ],
        ReportDataType.DATA,
        true
      ),
    ],
    CASH_COUNTRY: () => [
      new ReportVisualizationModel(
        [new ReportVisualizationItemModel()],
        ReportDataType.TOTAL
      ),
      ...(report.groupByFilter.groupBy.length
        ? [
            new ReportVisualizationModel(
              [new ReportVisualizationItemModel()],
              ReportDataType.SUB_TOTAL
            ),
          ]
        : []),
      new ReportVisualizationModel(
        [new ReportVisualizationItemModel()],
        ReportDataType.DATA
      ),
    ],
    CASH_GAMING: () => [
      new ReportVisualizationModel(
        [new ReportVisualizationItemModel()],
        ReportDataType.TOTAL
      ),
      ...(report.groupByFilter.groupBy.length
        ? [
            new ReportVisualizationModel(
              [new ReportVisualizationItemModel()],
              ReportDataType.SUB_TOTAL
            ),
          ]
        : []),
      new ReportVisualizationModel(
        [new ReportVisualizationItemModel()],
        ReportDataType.DATA
      ),
    ],
    COHORT_ANALYSIS: () => [
      new ReportVisualizationModel(
        [new ReportVisualizationItemModel()],
        ReportDataType.TOTAL
      ),
      new ReportVisualizationModel(
        [new ReportVisualizationItemModel()],
        ReportDataType.SUB_TOTAL,
        undefined,
        undefined,
        "topCampaignsByArpu"
      ),
      new ReportVisualizationModel(
        [new ReportVisualizationChartModel(ChartName.RETENTION_RATE, false)],
        ReportDataType.RETENTION_RATE
      ),
      new ReportVisualizationModel(
        [new ReportVisualizationItemModel()],
        ReportDataType.RETENTION_RATE_COHORT
      ),
      new ReportVisualizationModel(
        [new ReportVisualizationChartModel(ChartName.ROAS_N_DAY, false)],
        ReportDataType.ROAS_N_DAY
      ),
      new ReportVisualizationModel(
        [new ReportVisualizationItemModel()],
        ReportDataType.ROAS_N_DAY_COHORT
      ),
      new ReportVisualizationModel(
        [new ReportVisualizationChartModel(ChartName.DAU_DYNAMICS, false)],
        ReportDataType.DAU_DYNAMICS
      ),
      new ReportVisualizationModel(
        [
          new ReportVisualizationChartModel(
            ChartName.REVENUE_SPENDINGS_DAILY_DYNAMICS,
            false
          ),
        ],
        ReportDataType.REVENUE_SPENDINGS_DAILY_DYNAMICS
      ),
      new ReportVisualizationModel(
        [
          new ReportVisualizationChartModel(
            ChartName.CUMULATED_REVENUE_SPENDINGS_PROFIT,
            false
          ),
        ],
        ReportDataType.CUMULATED_REVENUE_SPENDINGS_PROFIT
      ),
      new ReportVisualizationModel(
        [
          new ReportVisualizationChartModel(
            ChartName.CUMULATED_ARPU_STRUCTURE,
            false
          ),
        ],
        ReportDataType.CUMULATED_ARPU_STRUCTURE
      ),
      new ReportVisualizationModel(
        [new ReportVisualizationChartModel(ChartName.CUMULATED_ARPU, false)],
        ReportDataType.CUMULATED_ARPU
      ),
      new ReportVisualizationModel(
        [new ReportVisualizationItemModel()],
        ReportDataType.CUMULATED_ARPU_COHORT
      ),
      new ReportVisualizationModel(
        [
          new ReportVisualizationChartModel(
            ChartName.CUMULATED_PLAYTIME_PER_ACTIVE_USER,
            false
          ),
        ],
        ReportDataType.CUMULATED_PLAYTIME_PER_ACTIVE_USER
      ),
      new ReportVisualizationModel(
        [new ReportVisualizationItemModel()],
        ReportDataType.CUMULATED_PLAYTIME_PER_ACTIVE_USER_COHORT
      ),
    ],
    COHORT_CPM: () => [
      new ReportVisualizationModel(
        [
          new ReportVisualizationItemModel(),
          new ReportVisualizationChartModel(ChartName.COHORT_CPM_TOTAL_CHART),
        ],
        ReportDataType.TOTAL,
        true
      ),
      ...(report.groupByFilter.groupBy.length
        ? [
            new ReportVisualizationModel(
              [
                new ReportVisualizationItemModel(),
                new ReportVisualizationChartModel(
                  ChartName.COHORT_CPM_SUBTOTAL_CHART
                ),
              ],
              ReportDataType.SUB_TOTAL,
              true
            ),
          ]
        : []),
      new ReportVisualizationModel(
        [
          new ReportVisualizationItemModel(),
          ...report.charts.map(
            (name) => new ReportVisualizationChartModel(name)
          ),
        ],
        ReportDataType.DATA,
        true
      ),
    ],
    COHORT_CTR: () => [
      new ReportVisualizationModel(
        [
          new ReportVisualizationItemModel(),
          new ReportVisualizationChartModel(ChartName.COHORT_CPM_TOTAL_CHART),
        ],
        ReportDataType.TOTAL,
        true
      ),
      ...(report.groupByFilter.groupBy.length
        ? [
            new ReportVisualizationModel(
              [
                new ReportVisualizationItemModel(),
                new ReportVisualizationChartModel(
                  ChartName.COHORT_CPM_SUBTOTAL_CHART
                ),
              ],
              ReportDataType.SUB_TOTAL,
              true
            ),
          ]
        : []),
      new ReportVisualizationModel(
        [
          new ReportVisualizationItemModel(),
          ...report.charts.map(
            (name) => new ReportVisualizationChartModel(name)
          ),
        ],
        ReportDataType.DATA,
        true
      ),
    ],
    CALENDAR_CPM: () => [
      new ReportVisualizationModel(
        [
          new ReportVisualizationItemModel(),
          ...report.charts.map(
            (name) => new ReportVisualizationChartModel(name)
          ),
        ],
        ReportDataType.DATA,
        true
      ),
    ],
    COHORT_PER_MIN: () => [
      new ReportVisualizationModel(
        [new ReportVisualizationItemModel()],
        ReportDataType.TOTAL
      ),
      ...(report.groupByFilter.groupBy.length
        ? [
            new ReportVisualizationModel(
              [new ReportVisualizationItemModel()],
              ReportDataType.SUB_TOTAL
            ),
          ]
        : []),
      new ReportVisualizationModel(
        [
          new ReportVisualizationItemModel(),
          ...report.charts.map(
            (name) => new ReportVisualizationChartModel(name)
          ),
        ],
        ReportDataType.DATA,
        true
      ),
    ],
    DIFF_INSTALLS_STORES: () => [
      new ReportVisualizationModel(
        [new ReportVisualizationItemModel()],
        ReportDataType.TOTAL
      ),
      new ReportVisualizationModel(
        [
          new ReportVisualizationItemModel(),
          ...report.charts.map(
            (name) => new ReportVisualizationChartModel(name)
          ),
        ],
        ReportDataType.DATA,
        true
      ),
    ],
    EVENTS_COST: () => [
      new ReportVisualizationModel(
        [new ReportVisualizationItemModel()],
        ReportDataType.TOTAL
      ),
      ...(report.groupByFilter.groupBy.length
        ? [
            new ReportVisualizationModel(
              [new ReportVisualizationItemModel()],
              ReportDataType.SUB_TOTAL
            ),
          ]
        : []),
      new ReportVisualizationModel(
        [new ReportVisualizationItemModel()],
        ReportDataType.DATA
      ),
    ],
    EVENTS_SUMMARY: () => [
      new ReportVisualizationModel(
        [
          new ReportVisualizationItemModel(),
          ...report.charts.map(
            (name) => new ReportVisualizationChartModel(name)
          ),
        ],
        ReportDataType.DATA,
        true,
        true
      ),
    ],
    FILL_RATE: () => [
      new ReportVisualizationModel(
        [
          new ReportVisualizationItemModel(),
          ...report.charts.map(
            (name) => new ReportVisualizationChartModel(name)
          ),
        ],
        ReportDataType.DATA,
        true
      ),
    ],
    MEASURES_LITE: () => [
      new ReportVisualizationModel(
        [
          new ReportVisualizationItemModel(),
          new ReportVisualizationChartModel(
            ChartName.MEASURES_LITE_REPORT_TOTAL_CHART
          ),
        ],
        ReportDataType.TOTAL,
        true
      ),
      ...(report.groupByFilter.groupBy.length
        ? [
            new ReportVisualizationModel(
              [
                new ReportVisualizationItemModel(),
                new ReportVisualizationChartModel(
                  ChartName.MEASURES_LITE_REPORT_SUBTOTAL_CHART
                ),
              ],
              ReportDataType.SUB_TOTAL,
              true
            ),
          ]
        : []),
      new ReportVisualizationModel(
        [
          new ReportVisualizationItemModel(),
          ...report.charts.map(
            (name) => new ReportVisualizationChartModel(name)
          ),
        ],
        ReportDataType.DATA,
        true
      ),
    ],
    METRICS: () => [
      new ReportVisualizationModel(
        [
          new ReportVisualizationItemModel(),
          new ReportVisualizationChartModel(ChartName.METRICS_TOTAL_CHART),
        ],
        ReportDataType.TOTAL,
        true
      ),
      ...(report.groupByFilter.groupBy.length
        ? [
            new ReportVisualizationModel(
              [
                new ReportVisualizationItemModel(),
                ...(report.groupByFilter.groupBy.length < 2
                  ? [
                      new ReportVisualizationChartModel(
                        ChartName.RETURN_RATE_METRICS_SUBTOTAL_CHART
                      ),
                      new ReportVisualizationChartModel(
                        ChartName.TIME_SPENT_METRICS_SUBTOTAL_CHART
                      ),
                      new ReportVisualizationChartModel(
                        ChartName.ARPU_METRICS_SUBTOTAL_CHART
                      ),
                    ]
                  : []),
              ],
              ReportDataType.SUB_TOTAL,
              true
            ),
          ]
        : []),
      new ReportVisualizationModel(
        [
          new ReportVisualizationItemModel(),
          ...(report.groupByFilter.groupBy.length < 2
            ? report.charts.map(
                (name) => new ReportVisualizationChartModel(name)
              )
            : []),
        ],
        ReportDataType.DATA,
        true
      ),
    ],
    METRICS_SPEND: () => [
      new ReportVisualizationModel(
        [
          new ReportVisualizationItemModel(),
          new ReportVisualizationChartModel(
            ChartName.METRICS_SPEND_TOTAL_CHART
          ),
        ],
        ReportDataType.TOTAL,
        true
      ),
      ...(report.groupByFilter.groupBy.length
        ? [
            new ReportVisualizationModel(
              [
                new ReportVisualizationItemModel(),
                ...(report.groupByFilter.groupBy.length < 2
                  ? [
                      new ReportVisualizationChartModel(
                        ChartName.METRICS_SPEND_RETURN_RATE_SUBTOTAL_CHART
                      ),
                      new ReportVisualizationChartModel(
                        ChartName.METRICS_SPEND_TIME_SPENT_SUBTOTAL_CHART
                      ),
                      new ReportVisualizationChartModel(
                        ChartName.METRICS_SPEND_ENGAGEMENT_SUBTOTAL_CHART
                      ),
                      new ReportVisualizationChartModel(
                        ChartName.METRICS_SPEND_VALUE_SUBTOTAL_CHART
                      ),
                    ]
                  : []),
              ],
              ReportDataType.SUB_TOTAL,
              true
            ),
          ]
        : []),
      new ReportVisualizationModel(
        [
          new ReportVisualizationItemModel(),
          ...(report.groupByFilter.groupBy.length < 2
            ? report.charts.map(
                (name) => new ReportVisualizationChartModel(name)
              )
            : []),
        ],
        ReportDataType.DATA,
        true
      ),
    ],
    PAYING_USERS_CONVERSION: () => [
      new ReportVisualizationModel(
        [new ReportVisualizationItemModel()],
        ReportDataType.TOTAL
      ),
      new ReportVisualizationModel(
        [new ReportVisualizationItemModel()],
        ReportDataType.DATA
      ),
    ],
    PAYOUTS: () => [
      new ReportVisualizationModel(
        [new ReportVisualizationItemModel()],
        ReportDataType.DATA,
        false,
        true
      ),
      new ReportVisualizationModel(
        [new ReportVisualizationItemModel()],
        ReportDataType.MONTH_TOTAL
      ),
    ],
    PROFIT: () => [
      new ReportVisualizationModel(
        [
          new ReportVisualizationItemModel(),
          ...report.charts.map(
            (name) => new ReportVisualizationChartModel(name)
          ),
        ],
        ReportDataType.DATA,
        true,
        true
      ),
    ],
    RETURN_RATE: () => [
      new ReportVisualizationModel(
        [
          new ReportVisualizationItemModel(),
          new ReportVisualizationChartModel(
            ChartName.RETURN_RATE_REPORT_TOTAL_CHART
          ),
        ],
        ReportDataType.TOTAL,
        true
      ),
      ...(report.groupByFilter.groupBy.length
        ? [
            new ReportVisualizationModel(
              [
                new ReportVisualizationItemModel(),
                ...(report.groupByFilter.groupBy.length < 2
                  ? [
                      new ReportVisualizationChartModel(
                        ChartName.RETURN_RATE_REPORT_SUBTOTAL_CHART
                      ),
                    ]
                  : []),
              ],
              ReportDataType.SUB_TOTAL,
              true
            ),
          ]
        : []),
      new ReportVisualizationModel(
        [
          new ReportVisualizationItemModel(),
          ...(report.groupByFilter.groupBy.length < 2
            ? report.charts.map(
                (name) => new ReportVisualizationChartModel(name)
              )
            : []),
        ],
        ReportDataType.DATA,
        true
      ),
    ],
    REVENUE: () => [
      new ReportVisualizationModel(
        [
          new ReportVisualizationItemModel(),
          ...report.charts.map(
            (name) => new ReportVisualizationChartModel(name)
          ),
        ],
        ReportDataType.DATA,
        true,
        true
      ),
    ],
    SKAD: () => [
      new ReportVisualizationModel(
        [new ReportVisualizationItemModel()],
        ReportDataType.TOTAL
      ),
      ...(report.groupByFilter.groupBy.length
        ? [
            new ReportVisualizationModel(
              [new ReportVisualizationItemModel()],
              ReportDataType.SUB_TOTAL
            ),
          ]
        : []),
      new ReportVisualizationModel(
        [new ReportVisualizationItemModel()],
        ReportDataType.DATA
      ),
    ],
    SPEND: () => [
      new ReportVisualizationModel(
        [new ReportVisualizationItemModel()],
        ReportDataType.TOTAL
      ),
      ...(report.groupByFilter.groupBy.length
        ? [
            new ReportVisualizationModel(
              [new ReportVisualizationItemModel()],
              ReportDataType.SUB_TOTAL
            ),
          ]
        : []),
      new ReportVisualizationModel(
        [new ReportVisualizationItemModel()],
        ReportDataType.DATA
      ),
    ],
    SPEND_HYPER: () => [
      new ReportVisualizationModel(
        [new ReportVisualizationItemModel()],
        ReportDataType.TOTAL
      ),
      ...(report.groupByFilter.groupBy.length
        ? [
            new ReportVisualizationModel(
              [new ReportVisualizationItemModel()],
              ReportDataType.SUB_TOTAL
            ),
          ]
        : []),
      new ReportVisualizationModel(
        [new ReportVisualizationItemModel()],
        ReportDataType.DATA
      ),
    ],
    SPEND_MINI: () => [
      new ReportVisualizationModel(
        [new ReportVisualizationItemModel()],
        ReportDataType.DATA,
        false,
        true
      ),
    ],
    TIME_SPENT: () => [
      new ReportVisualizationModel(
        [
          new ReportVisualizationItemModel(),
          new ReportVisualizationChartModel(
            ChartName.TIME_SPENT_REPORT_TOTAL_CHART
          ),
        ],
        ReportDataType.TOTAL,
        true
      ),
      ...(report.groupByFilter.groupBy.length
        ? [
            new ReportVisualizationModel(
              [
                new ReportVisualizationItemModel(),
                ...(report.groupByFilter.groupBy.length < 2
                  ? [
                      new ReportVisualizationChartModel(
                        ChartName.TIME_SPENT_REPORT_SUBTOTAL_CHART
                      ),
                    ]
                  : []),
              ],
              ReportDataType.SUB_TOTAL,
              true
            ),
          ]
        : []),
      new ReportVisualizationModel(
        [
          new ReportVisualizationItemModel(),
          ...(report.groupByFilter.groupBy.length < 2
            ? report.charts.map(
                (name) => new ReportVisualizationChartModel(name)
              )
            : []),
        ],
        ReportDataType.DATA,
        true
      ),
    ],
    WATERFALL: () => [
      new ReportVisualizationModel(
        [new ReportVisualizationItemModel()],
        ReportDataType.DATA,
        false,
        true
      ),
      new ReportVisualizationModel(
        [new ReportVisualizationItemModel()],
        ReportDataType.SSB
      ),
      ...(report.hasCharts
        ? [
            new ReportVisualizationModel(
              report.charts.map(
                (name) => new ReportVisualizationChartModel(name)
              ),
              ReportDataType.SSB_CHART,
              undefined,
              undefined,
              "charts"
            ),
          ]
        : []),
    ],
    PRICED_REVENUE: () => [
      new ReportVisualizationModel(
        [
          new ReportVisualizationItemModel(),
          ...report.charts.map(
            (name) => new ReportVisualizationChartModel(name)
          ),
        ],
        ReportDataType.DATA,
        true,
        !report.groupByFilter.groupBy.includes(FilterId.SEGMENT)
      ),
    ],
    MONETIZATION_MONITORING: () => [
      ...((report as MonetizationMonitoringFilter).noMonitoringTable
        ? []
        : [
            new ReportVisualizationModel(
              [new ReportVisualizationItemModel()],
              ReportDataType.MONITORING,
              undefined,
              undefined,
              undefined,
              true
            ),
          ]),
      new ReportVisualizationModel(
        [
          new ReportVisualizationChartModel(
            ChartName.REVENUE_DIFF_PERCENT_DATE_COUNTRY_BASED_DIFF_REVENUE_DAY,
            false
          ),
        ],
        ReportDataType.REVENUE_DIFF_PERCENT_DATE_COUNTRY,
        false,
        false,
        "revenueDiffPercentDateCountryBasedDiffRevenueDay",
        true
      ),
      new ReportVisualizationModel(
        [
          new ReportVisualizationChartModel(
            ChartName.REVENUE_DIFF_PERCENT_DATE_COUNTRY_BASED_DIFF_IMPRESSIONS_DAY,
            false
          ),
        ],
        ReportDataType.REVENUE_DIFF_PERCENT_DATE_COUNTRY,
        false,
        false,
        "revenueDiffPercentDateCountryBasedDiffImpressionsDay",
        true
      ),
      new ReportVisualizationModel(
        [
          new ReportVisualizationChartModel(
            ChartName.REVENUE_DIFF_PERCENT_DATE_NETWORK_BASED_DIFF_REVENUE_DAY,
            false
          ),
        ],
        ReportDataType.REVENUE_DIFF_PERCENT_DATE_NETWORK,
        false,
        false,
        "revenueDiffPercentDateNetworkBasedDiffRevenueDay",
        true
      ),
      new ReportVisualizationModel(
        [
          new ReportVisualizationChartModel(
            ChartName.REVENUE_DIFF_PERCENT_DATE_NETWORK_BASED_DIFF_IMPRESSIONS_DAY,
            false
          ),
        ],
        ReportDataType.REVENUE_DIFF_PERCENT_DATE_NETWORK,
        false,
        false,
        "revenueDiffPercentDateNetworkBasedDiffImpressionsDay",
        true
      ),
      new ReportVisualizationModel(
        [
          new ReportVisualizationChartModel(
            ChartName.ARPDAU_DIFF_DATE_COUNTRY_BASED_DIFF_REVENUE_PREV_DAY,
            false
          ),
        ],
        ReportDataType.ARPDAU_DIFF_DATE_COUNTRY,
        false,
        false,
        "arpdauDiffDateCountryBasedDiffRevenuePrevDay",
        true
      ),
      new ReportVisualizationModel(
        [
          new ReportVisualizationChartModel(
            ChartName.ARPDAU_DIFF_DATE_COUNTRY_BASED_DIFF_IMPRESSIONS_PREV_DAY,
            false
          ),
        ],
        ReportDataType.ARPDAU_DIFF_DATE_COUNTRY,
        false,
        false,
        "arpdauDiffDateCountryBasedDiffImpressionsPrevDay",
        true
      ),
      new ReportVisualizationModel(
        [
          new ReportVisualizationChartModel(
            ChartName.ARPDAU_DIFF_DATE_COUNTRY_BASED_DIFF_REVENUE_PREV_YEAR,
            false
          ),
        ],
        ReportDataType.ARPDAU_DIFF_DATE_COUNTRY,
        false,
        false,
        "arpdauDiffDateCountryBasedDiffRevenuePrevYear",
        true
      ),
      new ReportVisualizationModel(
        [
          new ReportVisualizationChartModel(
            ChartName.ARPDAU_DIFF_DATE_COUNTRY_BASED_DIFF_IMPRESSIONS_PREV_YEAR,
            false
          ),
        ],
        ReportDataType.ARPDAU_DIFF_DATE_COUNTRY,
        false,
        false,
        "arpdauDiffDateCountryBasedDiffImpressionsPrevYear",
        true
      ),
      new ReportVisualizationModel(
        [
          new ReportVisualizationChartModel(
            ChartName.ARPDAU_DIFF_DATE_NETWORK_BASED_DIFF_REVENUE_PREV_DAY,
            false
          ),
        ],
        ReportDataType.ARPDAU_DIFF_DATE_NETWORK,
        false,
        false,
        "arpdauDiffDateNetworkBasedDiffRevenuePrevDay",
        true
      ),
      new ReportVisualizationModel(
        [
          new ReportVisualizationChartModel(
            ChartName.ARPDAU_DIFF_DATE_NETWORK_BASED_DIFF_IMPRESSIONS_PREV_DAY,
            false
          ),
        ],
        ReportDataType.ARPDAU_DIFF_DATE_NETWORK,
        false,
        false,
        "arpdauDiffDateNetworkBasedDiffImpressionsPrevDay",
        true
      ),
      new ReportVisualizationModel(
        [
          new ReportVisualizationChartModel(
            ChartName.ARPDAU_DIFF_DATE_NETWORK_BASED_DIFF_REVENUE_PREV_YEAR,
            false
          ),
        ],
        ReportDataType.ARPDAU_DIFF_DATE_NETWORK,
        false,
        false,
        "arpdauDiffDateNetworkBasedDiffRevenuePrevYear",
        true
      ),
      new ReportVisualizationModel(
        [
          new ReportVisualizationChartModel(
            ChartName.ARPDAU_DIFF_DATE_NETWORK_BASED_DIFF_IMPRESSIONS_PREV_YEAR,
            false
          ),
        ],
        ReportDataType.ARPDAU_DIFF_DATE_NETWORK,
        false,
        false,
        "arpdauDiffDateNetworkBasedDiffImpressionsPrevYear",
        true
      ),
    ],
    FIREBASE_SHOW_TO_IMPRESSION: () => [
      new ReportVisualizationModel(
        [new ReportVisualizationItemModel()],
        ReportDataType.DATA
      ),
    ],
    FIREBASE_VS_NETWORKS: () => [
      new ReportVisualizationModel(
        [new ReportVisualizationItemModel()],
        ReportDataType.DATA
      ),
    ],
    FIREBASE_FILL_RATE: () => [
      new ReportVisualizationModel(
        [new ReportVisualizationItemModel()],
        ReportDataType.DATA
      ),
    ],
    TRAFFIC_QUALITY: () => [
      new ReportVisualizationModel(
        [
          new ReportVisualizationItemModel(),
          new ReportVisualizationChartModel(
            ChartName.TRAFFIC_QUALITY_TOTAL_CHART
          ),
        ],
        ReportDataType.TOTAL,
        true
      ),
      ...(report.groupByFilter.groupBy.length
        ? [
            new ReportVisualizationModel(
              [
                new ReportVisualizationItemModel(),
                ...(report.groupByFilter.groupBy.length < 2
                  ? [
                      new ReportVisualizationChartModel(
                        ChartName.TRAFFIC_QUALITY_RETURN_RATE_SUBTOTAL_CHART
                      ),
                      new ReportVisualizationChartModel(
                        ChartName.TRAFFIC_QUALITY_TIME_SPENT_SUBTOTAL_CHART
                      ),
                      new ReportVisualizationChartModel(
                        ChartName.TRAFFIC_QUALITY_ARPU_SUBTOTAL_CHART
                      ),
                      new ReportVisualizationChartModel(
                        ChartName.TRAFFIC_QUALITY_ARPPU_SUBTOTAL_CHART
                      ),
                      new ReportVisualizationChartModel(
                        ChartName.TRAFFIC_QUALITY_PAYING_USERS_SUBTOTAL_CHART
                      ),
                    ]
                  : []),
              ],
              ReportDataType.SUB_TOTAL,
              true
            ),
          ]
        : []),
      new ReportVisualizationModel(
        [
          new ReportVisualizationItemModel(),
          ...(report.groupByFilter.groupBy.length < 2
            ? report.charts.map(
                (name) => new ReportVisualizationChartModel(name)
              )
            : []),
        ],
        ReportDataType.DATA,
        true
      ),
    ],
    UA_MAIN_METRICS_OVERVIEW: () => [
      new ReportVisualizationModel(
        [new ReportVisualizationItemModel()],
        ReportDataType.TOTAL
      ),
      ...(report.groupByFilter.groupBy.length
        ? [
            new ReportVisualizationModel(
              [new ReportVisualizationItemModel()],
              ReportDataType.SUB_TOTAL
            ),
          ]
        : []),
      new ReportVisualizationModel(
        [new ReportVisualizationItemModel()],
        ReportDataType.DATA
      ),
    ],
    SUBSCRIPTIONS_OVERVIEW: () => [
      new ReportVisualizationModel(
        [new ReportVisualizationItemModel()],
        ReportDataType.TOTAL
      ),
      new ReportVisualizationModel(
        [new ReportVisualizationItemModel()],
        ReportDataType.SUB_TOTAL
      ),
    ],
    METRICS_CONSTRUCTOR: () => [
      new ReportVisualizationModel(
        [new ReportVisualizationItemModel()],
        ReportDataType.TOTAL
      ),
      ...(report.groupByFilter.groupBy.length
        ? [
            new ReportVisualizationModel(
              [new ReportVisualizationItemModel()],
              ReportDataType.SUB_TOTAL
            ),
          ]
        : []),
      new ReportVisualizationModel(
        [new ReportVisualizationItemModel()],
        ReportDataType.DATA
      ),
    ],
    SUBSCRIPTIONS_OVERVIEW_IOS: () => [
      new ReportVisualizationModel(
        [new ReportVisualizationItemModel()],
        ReportDataType.TOTAL
      ),
      new ReportVisualizationModel(
        [new ReportVisualizationItemModel()],
        ReportDataType.SUB_TOTAL
      ),
    ],
  }[report.reportId]();
};
