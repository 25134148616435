












































import { Component, Vue, Watch } from "vue-property-decorator";
import { cloneDeep } from "lodash";
import moment from "moment";

import DatesPicker from "@/shared/components/pickers/DatesPicker.vue";
import { DatesFilterModel, FilterPreviewId } from "@/shared/models";
import { PayoutsFilter } from "@/reports/models";
import DateUtil from "@/shared/utils/DateUtil";
import DateFormats from "@/shared/utils/DateFormats";

@Component({
  components: {
    DatesPicker,
  },
})
export default class AccrualPeriodFilterVisualization extends Vue {
  readonly DATES_SEPARATOR = " - ";
  menu = false;
  required = false;
  dateFormatted = "";
  localDatesValue: Array<string> = [];

  get applicationId(): string {
    return this.$route.params.id;
  }

  get localReport(): PayoutsFilter {
    return this.$store.state.reportStore.localReport;
  }

  get localReportDate(): DatesFilterModel | undefined {
    return this.localReport.date;
  }

  get accrualPeriod(): DatesFilterModel | undefined {
    return this.localReport.accrualPeriod;
  }

  get valid(): boolean {
    return this.localDatesValue.every((value) => value !== "Invalid date");
  }

  get max(): string {
    return this.localReport.date?.to ? this.localReport.date.to : "";
  }

  get inputErrorMessage(): Array<string> {
    const arrayFromLocalValueText = this.dateFormatted
      ? this.dateFormatted
          .split(this.DATES_SEPARATOR)
          .filter((item: string) => !!item)
      : [];
    const hasDateGreaterThanMaxDate = arrayFromLocalValueText.some((item) =>
      moment(DateUtil.formatFromDefaultToDatePicker(item)).isAfter(this.max)
    );
    const hasInvalidDate = arrayFromLocalValueText.some(
      (item) =>
        !DateUtil.isValidDate(DateUtil.formatFromDefaultToMonthPicker(item))
    );

    if (this.max && hasDateGreaterThanMaxDate) {
      return [this.$lang("validation.dateGreaterThanMaxDate")];
    }

    if (hasInvalidDate) {
      return [this.$lang("validation.invalidDate")];
    }

    if (this.required && this.localDatesValue.every((item) => !item)) {
      return [this.$lang("validation.required")];
    }

    return [];
  }

  @Watch("accrualPeriod", { immediate: true })
  watchAccrualPeriod(value: DatesFilterModel) {
    if (value?.from || value?.to) {
      this.localDatesValue = [value.from, value.to];
    }
  }

  @Watch("localReportDate")
  watchLocalReportDate(value: DatesFilterModel) {
    this.required = !value;
  }

  @Watch("localDatesValue", { immediate: true, deep: true })
  watchLocalDatesValue() {
    if (!this.localDatesValue.length) {
      this.setRequiredPropForLocalReportDate(true);
    }

    this.fillLocalValueText();
  }

  @Watch("applicationId")
  watchApplicationId() {
    this.localDatesValue = [];
  }

  fillLocalValueText() {
    if (this.localDatesValue.length) {
      this.dateFormatted = this.localDatesValue
        .map((it) => DateUtil.format(it, DateFormats.MONTH_PICKER_VIEW_FORMAT))
        .join(this.DATES_SEPARATOR);
    } else {
      this.dateFormatted = "";
    }
  }

  onLocalValueChange(value: Array<string>) {
    if (value && value.length === 2 && value[0] > value[1]) {
      value.sort();
    }

    this.setRequiredPropForLocalReportDate(false);
    this.fillLocalValueText();
    (this.$refs.menuDates as any).save(this.localDatesValue);
    this.setAccrualPeriod(value);
    this.menu = false;
  }

  clearLocalValue() {
    this.dateFormatted = "";
    this.localDatesValue = [];
    this.setAccrualPeriod(this.localDatesValue);
  }

  setAccrualPeriod(value: Array<string>) {
    const clonedLocalReport = cloneDeep(this.localReport);
    clonedLocalReport.accrualPeriod = value.length
      ? new DatesFilterModel(
          FilterPreviewId.ACCRUAL_DATE_VALUE as any,
          this.required,
          true,
          this.valid,
          `${value[0]}-01`,
          `${value[1]}-01`
        )
      : undefined;
    this.$store.commit("updateLocalReport", clonedLocalReport);
  }

  setRequiredPropForLocalReportDate(value: boolean) {
    const clonedLocalReport: any = cloneDeep(this.localReport);
    clonedLocalReport.date = this.localReport?.date
      ? new DatesFilterModel(
          this.localReport.date.id,
          value,
          this.localReport.date.filled,
          this.localReport.date.valid,
          this.localReport.date.from,
          this.localReport.date.to,
          this.localReport.date.clearable
        )
      : undefined;
    this.$store.commit("updateLocalReport", clonedLocalReport);
  }
}
